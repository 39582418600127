import addperfil from './pages/AddPerfil'

export default [
    {
        path: '/jira',
        name: 'jira',
        meta: {
            title: "Jira",
            permission: 'jira.add'
        },
        component: addperfil
    }
    
];